export const descriptionList = [
  {
    question: ["Ваш семейный статус"],
    description: {
      paragraphList: [
        {
          paragraph:
            "Семейный статус — это юридический и социальный статус человека в контексте его семейных отношений. Он обозначает положение человека в семье, включая его супружеский статус (например, состоит в браке, разведен, вдовец и т. д.), а также наличие или отсутствие детей.",
        },
      ],
    },
  },
  {
    question: ["Наличие лиц на Вашем иждивении"],
    description: {
      paragraphList: [
        {
          paragraph:
            "Иждивенцы: члены домохозяйства, которые финансово зависят от других людей в течение долгого времени. К ним относятся: ",
          list: [
            "все несовершеннолетние;",
            "студенты дневных отделений вузов и ссузов в возрасте до 23 лет;",
            "инвалиды, которые не в состоянии работать и обеспечивать себя;",
            "люди пенсионного возраста.",
          ],
        },
      ],
    },
  },
  {
    question: [
      "Уровень Ваших личных доходов (тысяч рублей в месяц)",
      "Источники ваших доходов",
    ],
    description: {
      paragraphList: [
        {
          paragraph:
            "Личный доход – это сумма денежных средств, которую получает физическое лицо из различных источников в процессе жизнедеятельности за определенный промежуток времени. К личным доходам можно отнести не только заработную плату, но и ряд дополнительных доходов (к примеру, дивиденды по ценным бумагам, трансферы, пенсию, социальные выплаты, плату за аренду и так далее).",
        },
      ],
    },
  },
  {
    question: ["Имеете ли Вы право на социальные льготы?"],
    description: {
      paragraphList: [
        {
          paragraph:
            "Социальные льготы — это преимущества, которые предоставляются государством разным категориям граждан России. Государство устанавливает такие меры социальной поддержки как: ",
          list: [
            "жилищные льготы;",
            "транспортные льготы;",
            "льготы в сфере здравоохранения;",
            "образовательные льготы;",
            "налоговые льготы.",
          ],
        },
      ],
    },
  },
  {
    question: [
      "Движимое и недвижимое имущество, находящиеся в Вашей собственности",
    ],
    description: {
      paragraphList: [
        {
          paragraph:
            "К недвижимым вещам относятся жилые и нежилые помещения, а также предназначенные для размещения транспортных средств части зданий или сооружений (машино-места), если границы таких помещений, частей зданий или сооружений описаны в установленном законодательством о государственном кадастровом учете порядке. Вещи, не относящиеся к недвижимости, включая деньги и ценные бумаги, признаются движимым имуществом.",
        },
      ],
    },
  },
  {
    question: ["Наличие сбережений"],
    description: {
      paragraphList: [
        {
          paragraph:
            "Сбережения — часть полученного за некоторый период дохода, не потраченная на текущее потребление, а сохраненная и вложенная для использования в будущем.",
        },
      ],
    },
  },
  {
    question: [
      "Получали ли Вы дополнительное образование? (Получаете ли в настоящее время?)",
    ],
    description: {
      paragraphList: [
        {
          paragraph:
            "Дополнительное образование — вид образования, направленный на всестороннее удовлетворение образовательных потребностей человека в интеллектуальном, духовно-нравственном, физическом и профессиональном совершенствовании: услуга, предоставляемая государственными и муниципальными организациями дополнительного образования, а также организациями, получившими лицензию на право ведения образовательной деятельности по дополнительному образованию детей и взрослых.",
        },
      ],
    },
  },
  {
    question: [
      "Есть ли у Вас просроченные кредитные (ипотечные) обязательства?",
    ],
    description: {
      paragraphList: [
        {
          paragraph:
            "Кредитный договор - это вид договора займа, договор между кредитором и заёмщиком, в соответствии с которым кредитор обязуются предоставить денежные средства (кредит) заёмщику в размере и на условиях, предусмотренных договором, а заемщик обязуется возвратить полученную денежную сумму и уплатить проценты за пользование ею, а также предусмотренные кредитным договором иные платежи, в том числе связанные с предоставлением кредита.",
        },
        {
          paragraph:
            " Ипотечный кредит - кредит под залог недвижимости. При выдаче ипотечного кредита банк берёт в залог землю и жилье после его постройки и регистрации.",
        },
      ],
    },
  },
  {
    question: [
      "Есть ли у Вас просроченные другие финансовые обязательства (алименты, штрафы и т.д.)?",
    ],
    description: {
      paragraphList: [
        {
          paragraph:
            "Алименты — это материальное обеспечение, предоставляемое по закону родственником, отдельно живущим нетрудоспособным членам семьи (детям, родителям и т. п.).",
        },
      ],
    },
  },
  {
    question: ["Есть ли у Вас непогашенные судимости?"],
    description: {
      paragraphList: [
        {
          paragraph: "Судимость считается погашенной:",
          list: [
            "в отношении лиц, условно осужденных, - по истечении испытательного срока;",
            "в отношении лиц, осужденных к более мягким видам наказаний, чем лишение свободы, - по истечении одного года после отбытия или исполнения наказания;",
            "в отношении лиц, осужденных к лишению свободы за преступления небольшой или средней тяжести, - по истечении трех лет после отбытия наказания;",
            "в отношении лиц, осужденных к лишению свободы за тяжкие преступления, - по истечении шести лет после отбытия наказания;",
            "в отношении лиц, осужденных за особо тяжкие преступления, - по истечении восьми лет после отбытия наказания.",
          ],
        },
      ],
    },
  },
  {
    question: ["Ваше состояние здоровья"],
    description: {
      paragraphList: [
        {
          paragraph:
            "Здоровье – это состояние полного физического, духовного и социального благополучия, а не только отсутствие болезни и физических дефектов.",
        },
        {
          paragraph:
            " Хроническое заболевание - это состояние здоровья или болезнь, которое является стойким или иным образом длительным по своим последствиям.",
        },
      ],
    },
  },
  {
    question: ["Имеется ли у вас статус  инвалида?"],
    description: {
      paragraphList: [
        {
          paragraph:
            "Инвалидом признаётся человек, у которого есть заболевания, травмы или дефекты, которые не позволяют ему полноценно жить или работать. Такому человеку полагаются выплаты и льготы от государства. Взрослые инвалиды могут быть первой, второй или третьей групп. Отдельно выделяют детей-инвалидов до 18 лет, не разделяя их на группы.",
        },
      ],
    },
  },
  {
    question: ["Занимаетесь ли Вы спортом или каким-либо видом активности?"],
    description: {
      paragraphList: [
        {
          paragraph:
            "Спорт — организованная по определённым правилам деятельность людей, состоящая в сопоставлении их физических или интеллектуальных способностей. ",
        },
      ],
    },
  },
  {
    question: ["Придерживаетесь ли вы Здорового образа жизни?"],
    description: {
      paragraphList: [
        {
          paragraph:
            "Здоровый образ жизни (ЗОЖ) — образ жизни человека, помогающий сохранить здоровье и снизить риск неинфекционных заболеваний путём контроля над поведенческими факторами риска. Здоровый образ жизни подразумевает отказ от табака, употребления алкоголя и наркотических веществ, рациональное питание, физическую активность (физические упражнения, спорт и тому подобное), укрепление психического здоровья и другие меры по укреплению здоровья.",
        },
      ],
    },
  },
];
