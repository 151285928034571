import { useNavigate } from "react-router-dom";
import arrow from "../icons/arrow.png";

export function MainContent() {
  const navigate = useNavigate();

  function goRegistration() {
    navigate("/registration");
    localStorage.clear();
  }

  return (
    <div className="mainContent">
      <div className="mainPageSpan">
        Для начала прохождения опроса нажмите кнопку "Начать" и заполните
        регистрационную форму
      </div>
      <button className="btn" onClick={goRegistration}>
        <span>Начать</span>

        <img alt="" src={arrow}></img>
      </button>
    </div>
  );
}
