import {
  InputLabel,
  Select,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import { Header } from "../Components/Header";
import Footer from "../Components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import left from "../icons/more-group-left.png";
import right from "../icons/more-group-right.png";
import firstFile from "../docs/Пользовательское соглашение.pdf";
import secondFile from "../docs/Согласие на обработку персональных данных.pdf";
import thirdFile from "../docs/Соглашение о защите от копирования и распространения информации.pdf";
import arrow from "../icons/arrow.png";
import { Label } from "@mui/icons-material";

const regions = [
  "Республика Адыгея",
  "Республика Башкортостан",
  "Республика Бурятия",
  "Республика Алтай (Горный Алтай)",
  "Республика Дагестан",
  "Республика Ингушетия",
  "Кабардино-Балкарская Республика",
  "Республика Калмыкия",
  "Республика Карачаево-Черкессия",
  "Республика Карелия",
  "Республика Коми",
  "Республика Марий Эл",
  "Республика Мордовия",
  "Республика Саха (Якутия)",
  "Республика Северная Осетия — Алания",
  "Республика Татарстан",
  "Республика Тыва",
  "Удмуртская Республика",
  "Республика Хакасия",
  "Чувашская Республика",
  "Алтайский край",
  "Краснодарский край",
  "Красноярский край",
  "Приморский край",
  "Ставропольский край",
  "Хабаровский край",
  "Амурская область",
  "Архангельская область",
  "Астраханская область",
  "Белгородская область",
  "Брянская область",
  "Владимирская область",
  "Волгоградская область",
  "Вологодская область",
  "Воронежская область",
  "Ивановская область",
  "Иркутская область",
  "Калининградская область",
  "Калужская область",
  "Камчатский край",
  "Кемеровская область",
  "Кировская область",
  "Костромская область",
  "Курганская область",
  "Курская область",
  "Ленинградская область",
  "Липецкая область",
  "Магаданская область",
  "Московская область",
  "Мурманская область",
  "Нижегородская область",
  "Новгородская область",
  "Новосибирская область",
  "Омская область",
  "Оренбургская область",
  "Орловская область",
  "Пензенская область",
  "Пермский край",
  "Псковская область",
  "Ростовская область",
  "Рязанская область",
  "Самарская область",
  "Саратовская область",
  "Сахалинская область",
  "Свердловская область",
  "Смоленская область",
  "Тамбовская область",
  "Тверская область",
  "Томская область",
  "Тульская область",
  "Тюменская область",
  "Ульяновская область",
  "Челябинская область",
  "Забайкальский край",
  "Ярославская область",
  "г. Москва",
  "г. Санкт-Петербург",
  "Еврейская автономная область",
  "Республика Крым",
  "Ненецкий автономный округ",
  "Ханты-Мансийский автономный округ — Югра",
  "Чукотский автономный округ",
  "Ямало-Ненецкий автономный округ",
  "г. Севастополь",
  "территории, находящиеся за пределами РФ (режимные объекты)",
  "Чеченская республика",
  "Донецкая Народная Республика",
  "Луганская Народная Республика",
  "Херсонская область",
  "Запорожская область",
];

const monthList = [
  { value: "1", month: "Январь" },
  { value: "2", month: "Февраль" },
  { value: "3", month: "Март" },
  { value: "4", month: "Апрель" },
  { value: "5", month: "Май" },
  { value: "6", month: "Июнь" },
  { value: "7", month: "Июль" },
  { value: "8", month: "Август" },
  { value: "9", month: "Сентябрь" },
  { value: "10", month: "Октябрь" },
  { value: "11", month: "Ноябрь" },
  { value: "12", month: "Декабрь" },
];

function yearDiff(firstDate, secondDate) {
  var diffYear =
    (new Date(secondDate).getTime() - new Date(firstDate).getTime()) / 1000;

  diffYear /= 60 * 60 * 24;
  return Math.abs(Math.round(diffYear / 365.25));
}

export function Registration() {
  const [languages, setLanguages] = useState([
    "абазинский",
    "адыгейский",
    "алтайский",
    "армянский",
    "белорусский",
    "башкирский",
    "бурятский",
    "грузинский",
    "ингушский",
    "кабардино-черкесский",
    "калмыцкий",
    "карачаево-балкарский",
    "киргизский",
    "коми",
    "крымскотатарский",
    "марийский",
    "мокшанский",
    "ногайский",
    "осетинский",
    "татарский",
    "тувинский",
    "удмуртский",
    "украинский",
    "хакасский",
    "чеченский",
    "чувашский",
    "эрзянский",
    "якутский",
    "аварский",
    "агульский",
    "азербайджанский",
    "даргинский",
    "кумыкский",
    "лакский",
    "лезгинский",
    "латвийский",
    "литовский",
    "рутульский",
    "табасаранский",
    "молдавский",
    "таджикский",
    "туркменский",
    "узбекский",
    "эстонский",
    "татский",
    "цахурский",
    "вепсский",
    "долганский",
    "идиш",
    "казахский",
    "коми-пермяцкий",
    "мансийский",
    "ненецкий",
    "селькупский",
    "чукотский",
    "финский",
    "хантыйский",
    "эвенкийский",
    "эвенский",
    "юкагирский",
  ]);
  const navigate = useNavigate();
  const [loading, setLoad] = useState(false);
  const [check, setCheck] = useState(false);
  const [gender, setGender] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [place, setPlace] = useState("");
  const [language, setLanguage] = useState("");
  const [nameVer, setNameVer] = useState(false);
  const [email, setEmail] = useState("");
  const [emailPass, setEmailPass] = useState(false);

  useEffect(() => {
    regions.sort();
  }, []);

  useEffect(() => {
    if (document.getElementById("name").value) {
      setNameVer(true);
    } else {
      setNameVer(false);
    }

    //В будущем, все эти поля вернуться!!

    // const dropArea = document.querySelector(".drag-area");
    // const dragText = document.querySelector(".dragHeader");

    // const fileButton = document.querySelector(".fileButton");
    // const fileInput = document.querySelector(".fileInput");
    // let file;
    // fileButton.onclick = () => {
    //   fileInput.click();
    // };

    // fileInput.addEventListener("change", function () {
    //   if (document.querySelector(".dropText")) {
    //     document.querySelector(".dropText").remove();
    //   }
    //   dropArea.classList.add("active");
    //   file = this.files[0].name;
    //   const dropText = document.createElement("span");
    //   dropText.className = "dropText";
    //   dropText.textContent = `Файл ${file} загружен`;
    //   dropArea.append(dropText);
    // });

    // dropArea.addEventListener("dragover", (event) => {
    //   event.preventDefault();
    //   dropArea.classList.add("active");
    //   dragText.textContent = "Отпустите чтобы загрузить файл";
    // });

    // dropArea.addEventListener("dragleave", () => {
    //   dropArea.classList.remove("active");
    //   dragText.textContent = "Перетащите файл";
    // });

    // dropArea.addEventListener("drop", (event) => {
    //   event.preventDefault();
    //   if (document.querySelector(".dropText")) {
    //     document.querySelector(".dropText").remove();
    //   }
    //   file = event.dataTransfer.files[0].name;
    //   const dropText = document.createElement("span");
    //   dropText.className = "dropText";
    //   dropText.textContent = `Файл ${file} загружен`;
    //   dropArea.append(dropText);
    // });

    // const dragPasport = document.querySelector(".drag-pasport");
    // const pasportHeader = document.querySelector(".pasportHeader");

    // const pasportButton = document.querySelector(".pasportButton");
    // const filePasport = document.querySelector(".filePasport");
    // let pasport;
    // pasportButton.onclick = () => {
    //   filePasport.click();
    // };

    // filePasport.addEventListener("change", function () {
    //   if (document.querySelector(".dropPasport")) {
    //     document.querySelector(".dropPasport").remove();
    //   }
    //   dragPasport.classList.add("active");
    //   pasport = this.files[0].name;
    //   const dropText = document.createElement("span");
    //   dropText.className = "dropPasport";
    //   dropText.textContent = `Файл ${pasport} загружен`;
    //   dragPasport.append(dropText);
    // });

    // dragPasport.addEventListener("dragover", (event) => {
    //   event.preventDefault();
    //   dragPasport.classList.add("active");
    //   pasportHeader.textContent = "Отпустите чтобы загрузить файл";
    // });

    // dragPasport.addEventListener("dragleave", () => {
    //   dragPasport.classList.remove("active");
    //   pasportHeader.textContent = "Перетащите файл";
    // });

    // dragPasport.addEventListener("drop", (event) => {
    //   event.preventDefault();
    //   if (document.querySelector(".dropPasport")) {
    //     document.querySelector(".dropPasport").remove();
    //   }
    //   pasport = event.dataTransfer.files[0].name;
    //   const dropText = document.createElement("span");
    //   dropText.className = "dropPasport";
    //   dropText.textContent = `Файл ${pasport} загружен`;
    //   dragPasport.append(dropText);
    // });
  });

  function isValidDate(dateDay, dateMonth, dateYear) {
    let date = new Date(dateYear, dateMonth - 1, dateDay);

    if (
      date.getFullYear().toString() === dateYear.toString() &&
      date.getMonth().toString() === (dateMonth - 1).toString() &&
      date.getDate().toString() === dateDay.toString()
    ) {
      return true;
    } else {
      return false;
    }
  }

  function checkApprove() {
    if (check) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  }

  useEffect(() => {
    setLanguages(["русский", ...languages]);
  }, []);

  const mailTest = /^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i;

  useEffect(() => {
    if (email.length > 0 && mailTest.test(email)) {
      setEmailPass(true);
    }
    if (email.length === 0) {
      setEmailPass(true);
    }
    if (email.length > 0 && !mailTest.test(email)) {
      setEmailPass(false);
    }
  });

  async function startTest(e) {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;

    const birthDay =
      year + "-" + month.padStart(2, "0") + "-" + day.padStart(2, "0");

    let dateResult = yearDiff(today, birthDay);

    if (
      (14 > dateResult || dateResult > 80) &&
      year.length > 0 &&
      month.length > 0 &&
      day.length > 0
    ) {
      e.preventDefault();
      alert("Введите возраст от 14 до 80");
    }

    if (!isValidDate(day, month, year)) {
      e.preventDefault();
      alert("Дата введена некорректно");
    }

    if (
      14 <= dateResult &&
      dateResult <= 80 &&
      check &&
      nameVer &&
      language.length !== 0 &&
      place.length !== 0 &&
      emailPass &&
      gender
    ) {
      localStorage.setItem("fullName", document.getElementById("name").value);
      localStorage.setItem("email", document.getElementById("email").value);
      localStorage.setItem("gender", gender);
      localStorage.setItem("birthDate", year + "-" + month + "-" + day);
      localStorage.setItem("language", language);
      localStorage.setItem("place", place);
      localStorage.setItem("next", "start");

      const formdata = new FormData();
      formdata.append("fullName", localStorage.getItem("fullName"));
      formdata.append("email", localStorage.getItem("email"));
      formdata.append("gender", localStorage.getItem("gender"));
      formdata.append("birthDate", localStorage.getItem("birthDate"));
      formdata.append("language", localStorage.getItem("language"));
      formdata.append("place", localStorage.getItem("place"));
      formdata.append("next", localStorage.getItem("next"));

      setLoad(true);

      await axios
        // .get("https://bot.rgsu.net/we/main.py", {
        //   params: {
        //     fullName: localStorage.getItem("fullName"),
        //     email: localStorage.getItem("email"),
        //     gender: localStorage.getItem("gender"),
        //     birthDate: localStorage.getItem("birthDate"),
        //     language: localStorage.getItem("language"),
        //     place: localStorage.getItem("place"),
        //     next: localStorage.getItem("next"),
        //   },
        .post("https://we.rgsu.net/we.py", formdata)
        .then((response) => {
          setLoad(false);
          const parser = new DOMParser();
          const xml = parser.parseFromString(response.data, "application/xml");

          localStorage.setItem("pageNumber", 0);

          localStorage.setItem(
            "questionNumber",
            xml.querySelector("step").textContent
          );
          localStorage.setItem(
            "question",
            xml.querySelector("query").textContent
          );
          let answers = xml.querySelectorAll("ans");
          let answersList = [];

          for (let i = 0; i < answers.length; i++) {
            answersList.push(answers[i].textContent);
          }

          localStorage.setItem("answers", JSON.stringify(answersList));

          let answersId = xml.querySelectorAll("id");
          let answersIdList = [];

          for (let i = 0; i < answersId.length; i++) {
            answersIdList.push(answersId[i].textContent);
          }

          localStorage.setItem("id", JSON.stringify(answersIdList));
          localStorage.setItem("multi", xml.querySelector("multi").textContent);
          localStorage.setItem(
            "sessionid",
            xml.querySelector("sessionid").textContent
          );
          localStorage.setItem(
            "tdelta",
            xml.querySelector("tdelta").textContent
          );
        });

      window.scrollTo(0, 0);
      navigate("/test");
    }
  }

  function changeEmail(e) {
    setEmail(e.target.value);
  }

  function changeDay(e) {
    let onlyNums = e.target.value.replace(/[^0-9]/g, "");

    if (onlyNums.startsWith("0") && onlyNums.length > 1) {
      onlyNums = onlyNums.slice(1);
    }

    if (onlyNums.length < 3) {
      setDay(onlyNums);
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      setDay(number);
    }
  }

  function changeYear(e) {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 5) {
      setYear(onlyNums);
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");

      setYear(number);
    }
  }

  return (
    <div>
      <Header></Header>{" "}
      <div className="backGroundLeft">
        <img alt="" src={left}></img>
      </div>
      <div className="backGroundRight">
        <img alt="" src={right}></img>
      </div>
      <div className="registrationContent">
        <div className="mainSpan">
          <div>Регистрация</div>
        </div>
        <div className="warningRegistration">
          Поля отмеченные символом * обязательны для заполнения
        </div>
        <form className="registrationForm">
          <TextField
            id="name"
            label="Введите ФИО или псевдоним"
            variant="standard"
            className="textInput"
            name="fullName"
            required
          />
          <TextField
            id="email"
            label="E-mail"
            variant="standard"
            className="textInput"
            type="email"
            name="email"
            value={email}
            onChange={changeEmail}
          />
          <FormControl>
            <InputLabel id="select-label" required>
              Выберите пол
            </InputLabel>
            <Select
              labelId="select-label"
              id="gender-select"
              label="Выберите пол"
              name="gender"
              native
              defaultValue=""
              onChange={(e) => {
                setGender(e.target.value);
              }}
              required
            >
              <option aria-label="None" value="" style={{ display: "none" }} />
              <option value="Мужчина">Мужчина</option>
              <option value="Женщина">Женщина</option>
            </Select>
          </FormControl>
          <div className="dateBox">
            <div>Дата рождения</div>
            <div className="dateInputBox">
              <TextField
                id="day"
                label="День"
                variant="outlined"
                className="textInput"
                type="number"
                name="day"
                value={day}
                onChange={changeDay}
              />
              <FormControl>
                <InputLabel id="select-label" required>
                  Месяц
                </InputLabel>
                <Select
                  labelId="select-label"
                  id="month-select"
                  label="Месяц"
                  name="month"
                  native
                  defaultValue=""
                  onChange={(e) => {
                    setMonth(e.target.value);
                  }}
                  required
                >
                  <option
                    aria-label="None"
                    value=""
                    style={{ display: "none" }}
                  />
                  {monthList.map((item) => {
                    return <option value={item.value}>{item.month}</option>;
                  })}
                </Select>
              </FormControl>
              <TextField
                id="year"
                label="Год"
                variant="outlined"
                className="textInput"
                type="number"
                name="year"
                value={year}
                onChange={changeYear}
              />
            </div>
          </div>

          <FormControl>
            <Autocomplete
              labelId="select-label"
              id="place-select"
              name="place"
              freeSolo
              disableClearable
              defaultValue=""
              onChange={(event, value) => {
                setPlace(value);
              }}
              options={regions}
              renderInput={(params) => (
                <TextField {...params} label="Регион проживания" required />
              )}
              required
            />
            {/* <InputLabel id="select-label" required>
              Регион проживания
            </InputLabel>
            <Select
              labelId="select-label"
              id="place-select"
              label="Регион проживания"
              name="place"
              native
              defaultValue=""
              onChange={(e) => {
                setPlace(e.target.value);
              }}
              required
            >
              <option aria-label="None" value="" style={{ display: "none" }} />
              {regions.map((item) => {
                return <option value={item}>{item}</option>;
              })}
            </Select> */}
          </FormControl>
          <FormControl>
            <Autocomplete
              labelId="select-label"
              id="language-select"
              name="language"
              freeSolo
              disableClearable
              defaultValue=""
              onChange={(event, value) => {
                setLanguage(value);
              }}
              options={languages}
              renderInput={(params) => (
                <TextField {...params} label=" Родной язык" required />
              )}
            />
            {/* < <InputLabel id="select-label" required>
              Родной язык
            </InputLabel>
            <Select
              labelId="select-label"
              id="language-select"
              label="Родной язык"
              name="language"
              native
              defaultValue=""
              onChange={(e) => {
                setLanguage(e.target.value);
              }}
              required
            >
              <option aria-label="None" value="" style={{ display: "none" }} />
              {languages.map((item) => {
                return <option value={item}>{item}</option>;
              })}
            </Select> /> */}
          </FormControl>
          <div className="pasportForm">
            <label
              className="pasportLabel"
              style={{ color: "red", fontSize: "18px", textAlign: "center" }}
            >
              {" "}
              Ввод персональных данных заблокирован. В настоящее время система
              работает в тестовом режиме и полностью анонимно.
            </label>
            <div className="drag-area">
              <div className="icon">
                <i className="fas fa-cloud-upload-alt"></i>
              </div>

              <header className="dragHeader">СНИЛС прикрепить файл</header>
              <span>или</span>
              <button className="fileButton">Выберите файл</button>
              {/* <input
              className="fileInput"
              type="file"
              hidden
              id="fsnils"
              name="fsnils"
            /> */}
            </div>
            <label className="pasportLabel">Введите паспортные данные </label>

            {/* <ReactInputMask mask="99-99 999999"> */}
            {/* {() => ( */}
            <TextField
              id="pasportNumber"
              label="Серия и номер паспорта"
              variant="standard"
              className="textInput"
              disabled
            />
            {/* )} */}
            {/* </ReactInputMask> */}

            <TextField
              id="gavePasport"
              label="Выдан"
              variant="standard"
              className="textInput"
              disabled
            />
            <TextField
              id="pasportDate"
              label="Дата выдачи"
              InputLabelProps={{ shrink: true }}
              type="date"
              className="textInput"
              disabled
            />
            {/* <ReactInputMask mask="999-999">
              {() => ( */}
            <TextField
              id="pasportCode"
              label="Код подразделения"
              variant="standard"
              className="textInput"
              disabled
            />
            {/* )}
            </ReactInputMask> */}

            <TextField
              id="birthPlace"
              label="Место рождения"
              variant="standard"
              className="textInput"
              disabled
            />
            <div className="drag-area drag-pasport">
              <div className="icon">
                <i className="fas fa-cloud-upload-alt"></i>
              </div>
              <header className="dragHeader pasportHeader">
                ПАСПОРТ прикрепить файл
              </header>
              <span>или</span>
              <button className="fileButton pasportButton">
                Выберите файл
              </button>
              {/* <input
                className="fileInput filePasport"
                type="file"
                hidden
                id="fpasport"
                name="fpasport"
              /> */}
            </div>
          </div>
          <a href={firstFile}>Пользовательское соглашение</a>
          <a href={secondFile}>Согласие на обработку персональных данных</a>
          <a href={thirdFile}>
            Соглашение о защите от копирования и распространения информации
          </a>
          <FormGroup className="pasportCheck">
            <FormControlLabel
              required
              control={<Checkbox />}
              label="Согласен на обработку персональных данных"
              id="registrationApprove"
              onChange={checkApprove}
              className="agreementText"
            />
          </FormGroup>
          <div className="loadingBox">
            {loading ? (
              <ReactLoading
                type="spinningBubbles"
                color="black"
                height={"50px"}
                width={"50px"}
              />
            ) : (
              <button className="btn" onClick={startTest}>
                <span>Зарегистрироваться</span>
                <img alt="" src={arrow} />
              </button>
            )}
          </div>
        </form>
      </div>
      <Footer></Footer>
    </div>
  );
}
