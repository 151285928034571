import { useNavigate } from "react-router-dom";

export function PopUp() {
  const navigate = useNavigate();
  const today = new Date();

  const date =
    (today.getDate().toString().length < 2
      ? "0" + today.getDate()
      : today.getDate()) +
    "-" +
    (today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    today.getFullYear();

  window.scrollTo(0, 0);
  document.body.style.overflow = "hidden";

  const closePop = () => {
    document.body.style.overflow = "";
    navigate("/");
    localStorage.clear();
  };

  const goStatistics = () => {
    document.body.style.overflow = "";
    navigate("/");
  };

  return (
    <div className="pop_up">
      <div className="pop_window final_window">
        <div className="closeBox">
          <button onClick={closePop}>&#215;</button>
        </div>
        <div className="finalMessage">
          {" "}
          <span>
            <span style={{ fontWeight: 700, marginLeft: "0" }}>
              Сохраните, пожалуйста, Ваш уникальный код и дату прохождения!
            </span>{" "}
            Они являются ключом для доступа к Вашим результатам. Воспользуйтесь
            кнопкой «Мой результат» на первой странице. ВНИМАНИЕ! Формирование
            результатов может занять какое-то время. <br></br>
            <div style={{ fontWeight: 700 }}>
              {localStorage.getItem("sessionid")}
              <br></br>
              {date}
            </div>
            {/* <br></br>Загрузка результатов займет некоторое время */}
          </span>
        </div>

        <button className="exitButton" onClick={goStatistics}>
          Согласен
        </button>
      </div>
    </div>
  );
}
