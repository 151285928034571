import { useEffect, useState } from "react";
import { PopUp } from "./PopUp";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Select,
  TextField,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import ReactLoading from "react-loading";
import { descriptionList } from "../docs/questionDescription";
import questionIcon from "../icons/question-mark.png";
import arrow from "../icons/arrow.png";
import cross from "../icons/cross.png";

export function TestContent() {
  const [loading, setLoad] = useState(false);
  const [testNumber, setNumber] = useState(
    Number(localStorage.getItem("questionNumber"))
  );
  const [date, setDate] = useState("");
  const [input, setInput] = useState(false);
  const [radio, setRadio] = useState(false);
  const [check, setCheck] = useState(false);
  const [inputV, setInputV] = useState("");
  const [inputType, setInputType] = useState("");
  const [radioV, setRadioV] = useState("");
  const [checkV, setCheckV] = useState("");
  const [placeHolder, setPlaceHolder] = useState("");
  const [answer, setAnswer] = useState();
  const [description, setDescription] = useState("");
  const [descriptionStatus, setDescriptionStatus] = useState(false);
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [variantsList, setVariants] = useState(
    JSON.parse(localStorage.getItem("answers"))
  );
  const [answerId, setId] = useState(JSON.parse(localStorage.getItem("id")));
  const [text, setText] = useState(localStorage.getItem("question"));
  const [pop, setPop] = useState(false);
  const [final, setFinal] = useState(
    JSON.parse(localStorage.getItem("final")) || false
  );
  const [last, setLast] = useState(0);
  const [tests, setTests] = useState({
    number: localStorage.getItem("questionNumber"),
    text: localStorage.getItem("question"),
    type: localStorage.getItem("multi"),
    variants: JSON.parse(localStorage.getItem("answers")),
    placeholder: "Выберите один вариант из списка",
    id: JSON.parse(localStorage.getItem("id")),
  });

  const [checkList, setCheckList] = useState([]);

  window.onbeforeunload = function () {
    return false;
  };

  useEffect(() => {
    variantsList.map((item) => {
      setCheckList((checkList) => [
        ...checkList,
        { label: item, checked: false },
      ]);
    });
  }, [variantsList]);

  function parseParams(params) {
    const keys = Object.keys(params);
    let options = "";

    keys.forEach((key) => {
      const isParamTypeObject = typeof params[key] === "object";
      const isParamTypeArray = isParamTypeObject && params[key].length >= 0;

      if (!isParamTypeObject) {
        options += `${key}=${params[key]}&`;
      }

      if (isParamTypeObject && isParamTypeArray) {
        params[key].forEach((element) => {
          options += `${key}=${element}&`;
        });
      }
    });

    return options ? options.slice(0, -1) : options;
  }

  async function nextTest(e) {
    e.preventDefault();

    window.scrollTo(0, 0);

    const formdata = new FormData();

    formdata.append("answerId", answer);
    formdata.append("next", "next");
    formdata.append("step", Number(localStorage.getItem("questionNumber")));
    formdata.append("sessionid", localStorage.getItem("sessionid"));
    formdata.append("tdelta", localStorage.getItem("tdelta"));

    if (JSON.parse(localStorage.getItem("final")) == false) {
      if (inputV && Number(inputV) == 0) {
        alert("Вводимое значение должно быть больше нуля");
      }
      if (
        (inputV.length != 0 && Number(inputV) > 0) ||
        radioV.length != 0 ||
        checkV != 0
      ) {
        setLoad(true);
        await axios
          // .get("https://www.anncom.ru/dialer/we/main.py", formdata)
          .post("https://we.rgsu.net/we.py", null, {
            params: {
              answerId: answer,
              next: "next",
              step: Number(localStorage.getItem("questionNumber")),
              sessionid: localStorage.getItem("sessionid"),
              tdelta: localStorage.getItem("tdelta"),
            },
            paramsSerializer: (params) => parseParams(params),
          })
          .then((response) => {
            setLoad(false);
            const parser = new DOMParser();
            const xml = parser.parseFromString(
              response.data,
              "application/xml"
            );

            localStorage.setItem(
              "pageNumber",
              Number(localStorage.getItem("pageNumber")) + 1
            );

            localStorage.setItem(
              "questionNumber",
              xml.querySelector("step").textContent
            );
            localStorage.setItem(
              "question",
              xml.querySelector("query").textContent
            );

            let answers = xml.querySelectorAll("ans");
            let answersList = [];

            for (let i = 0; i < answers.length; i++) {
              if (xml.querySelector("multi").textContent != "number") {
                let answer =
                  answers[i].textContent[0].toUpperCase() +
                  answers[i].textContent.slice(1);
                answersList.push(answer);
              } else {
                answersList.push(answers[i].textContent);
              }
            }

            localStorage.setItem("answers", JSON.stringify(answersList));

            let answersId = xml.querySelectorAll("id");
            let answersIdList = [];

            for (let i = 0; i < answersId.length; i++) {
              answersIdList.push(answersId[i].textContent);
            }

            localStorage.setItem("id", JSON.stringify(answersIdList));

            localStorage.setItem(
              "multi",
              xml.querySelector("multi").textContent
            );

            setNumber(xml.querySelector("step").textContent);
            setText(xml.querySelector("query").textContent);
            setVariants(answersList);
            setId(answersIdList);
            setLast(xml.querySelector("islast").textContent);

            setTests({
              number: xml.querySelector("step").textContent,
              text: xml.querySelector("query").textContent,
              type: localStorage.getItem("multi"),
              variants: answersList,
              placeholder: "Выберите один вариант из списка",
              id: answersIdList,
            });
          });

        setCheckList([]);
        setCheckV([]);
        setInputV("");
        setRadioV("");
        setAnswer();
        setDescriptionStatus(false);
        setDescriptionOpen(false);
      } else {
        return;
      }
    } else {
      setLoad(true);
      await axios.post("https://we.rgsu.net/we.py", null, {
        params: {
          answerId: answer,
          next: "next",
          step: Number(localStorage.getItem("questionNumber")),
          sessionid: localStorage.getItem("sessionid"),
          tdelta: localStorage.getItem("tdelta"),
          isLast: "1",
        },
        paramsSerializer: (params) => parseParams(params),
      });

      window.scrollTo(0, 0);
      setPop(true);
      setLoad(false);
      localStorage.setItem("final", false);
    }
  }

  function changeInput(index, itemLabel, id) {
    const exceptionsDictionary = [
      "Нет",
      "Не знаю, имею ли я социальные льготы",
      "Не имею прав на социальные льготы",
      "Нет имущества в собственности",
      "Не получал(а)",
    ];

    setCheckList(
      checkList.map((label, currentIndex) =>
        currentIndex === index ? { ...label, checked: !label.checked } : label
      )
    );
    if (exceptionsDictionary.includes(itemLabel)) {
      setCheckList(
        checkList.map((item) => {
          if (item.label == itemLabel && item.checked == false) {
            setAnswer(id);
            return { ...item, checked: true };
          }
          if (item.label == itemLabel && item.checked == true) {
            setAnswer([]);

            return { ...item, checked: false };
          } else {
            return { ...item, checked: false };
          }
        })
      );
    } else {
      setCheckList(
        checkList.map((item) => {
          if (
            !exceptionsDictionary.includes(item.label) &&
            item.label == itemLabel &&
            item.checked == false
          ) {
            return { ...item, checked: true };
          }
          if (
            !exceptionsDictionary.includes(item.label) &&
            item.label == itemLabel &&
            item.checked == true
          ) {
            return { ...item, checked: false };
          }
          if (exceptionsDictionary.includes(item.label)) {
            return { ...item, checked: false };
          }
          return item;
        })
      );
    }
  }

  useEffect(() => {
    if (last == 1) {
      setFinal(true);
    }

    if (testNumber == tests.number) {
      if (tests.type == "text") {
        setAnswer(inputV);
        setInput(true);
        setInputType("text");
        setPlaceHolder(tests.placeholder);
      } else if (tests.type == "number") {
        setAnswer(inputV);
        setInput(true);
        setInputType("number");
        setPlaceHolder(tests.placeholder);
      } else setInput(false);
      if (tests.type == "radio") {
        setAnswer(radioV);
        setRadio(true);
        setPlaceHolder("Выберите один вариант из списка");
      } else setRadio(false);
      if (tests.type == "checkbox") {
        setCheckV(answer);
      }
    }
  });

  useEffect(() => {
    if (tests.type == "checkbox") {
      setAnswer([]);
      setCheck(true);
      let checkboxes = document.querySelectorAll(
        "input[type='checkbox']:checked"
      );
      checkboxes.forEach((item) => {
        if (item.checked == true) {
          setAnswer((answer) => [...answer, item.value]);
        }
      });

      setPlaceHolder("Выберите несколько вариантов из списка");
    } else setCheck(false);
  }, [checkList]);

  const handleChange = (event) => {
    const result = event.target.value.replace(/[^0-9]+/g, "");

    setInputV(result);
  };

  const handlePress = (event) => {
    return (event.which > 47 && event.which < 58) || event.which == 13;
  };

  const handleText = (event) => {
    setInputV(event.target.value);
  };

  useEffect(() => {
    localStorage.setItem("final", final);
  }, [final]);

  useEffect(() => {
    descriptionList.map((item) => {
      if (item.question.find((element) => element === text)) {
        setDescription(item);
        setDescriptionStatus(true);
      }
    });
  }, [text]);

  return (
    <div>
      <link
        rel="stylesheet"
        href="https://code.getmdl.io/1.3.0/material.indigo-pink.min.css"
      />

      <div className="mainSpan">
        <div>Вопрос {Number(localStorage.getItem("pageNumber")) + 1}</div>
      </div>
      <div className="mainContent">
        <span className="headTest">
          <div>
            {text}
            {descriptionStatus && (
              <img
                alt=""
                className="qustionMark"
                src={questionIcon}
                onClick={() => setDescriptionOpen(true)}
              />
            )}
          </div>
          {descriptionOpen && (
            <div className="questionDescription">
              <div className="closeBox descriptionCloseBox">
                <img
                  alt=""
                  src={cross}
                  onClick={() => setDescriptionOpen(false)}
                />
              </div>
              <div className="questionDescriptionText">
                {" "}
                {description.description.paragraphList.map(
                  (item) => item.paragraph
                )}
                <ul>
                  {description.description.paragraphList.map((item) =>
                    item.list?.map((element) => {
                      return <li>{element}</li>;
                    })
                  )}
                </ul>
              </div>
            </div>
          )}

          {(radio || check) && (
            <span className="questionHolder" style={{ color: "#4a4a4a40" }}>
              {placeHolder}
            </span>
          )}
        </span>

        <div className="testContent">
          {input && inputType == "number" && (
            <TextField
              id="text-input"
              label="Введите"
              variant="standard"
              className="textInput answerTextInput"
              value={inputV}
              type="text"
              onChange={handleChange}
              onKeyDown={handlePress}
            />
          )}
          {input && inputType == "text" && (
            <TextField
              id="text-input"
              label="Введите"
              variant="standard"
              className="textInput answerTextInput"
              value={inputV}
              type="text"
              onChange={handleText}
            />
          )}
          {radio && (
            <FormControl>
              <InputLabel id="demo-simple-select-label">Выбор</InputLabel>
              <Select
                native
                labelId="demo-simple-select-label"
                id="simple-select"
                className="selectInput"
                label="Выбор"
                defaultValue=""
                value={radioV}
                onChange={(e) => {
                  setRadioV(e.target.value);
                }}
              >
                <option
                  aria-label="None"
                  value=""
                  style={{ display: "none" }}
                />
                {tests.variants.map((item) => {
                  let id;
                  tests.id.map((element) => {
                    if (
                      tests.variants.indexOf(item) == tests.id.indexOf(element)
                    ) {
                      id = element;
                    }
                  });

                  return (
                    <option key={item} value={id} className="testOption">
                      {item}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          )}
          {check && (
            <FormGroup className="checkBox">
              {checkList.map((item, index) => {
                let id;
                tests.id.map((element) => {
                  if (checkList.indexOf(item) == tests.id.indexOf(element)) {
                    id = element;
                  }
                });
                return (
                  <FormControlLabel
                    key={item.label}
                    className="checkLabel"
                    control={
                      <Checkbox
                        className="checkInput"
                        checked={item.checked}
                        onChange={() => changeInput(index, item.label, id)}
                      />
                    }
                    label={item.label}
                    value={id}
                  />
                );
              })}
            </FormGroup>
          )}
        </div>
        <div className="nextBox">
          {loading ? (
            <ReactLoading
              type="spinningBubbles"
              color="black"
              height={"50px"}
              width={"50px"}
            />
          ) : final ? (
            <button onClick={nextTest}>
              <span>Закончить</span>
              <img alt="" src={arrow} />
            </button>
          ) : (
            <button className="btn nextButton" onClick={nextTest}>
              <span>Далее</span>
              <img alt="" src={arrow} />
            </button>
          )}
        </div>
      </div>
      {pop && <PopUp />}
    </div>
  );
}
