import { HeaderLK } from "../Components/HeaderLK";
import { MainContent } from "../Components/MainContent";
import left from "../icons/more-group-left.png";
import right from "../icons/more-group-right.png";

export function ScoringLK() {
  return (
    <div className="mainPage">
      <HeaderLK></HeaderLK>
      <div className="backGroundLeft">
        <img src={left}></img>
      </div>
      <div className="backGroundRight">
        <img src={right}></img>
      </div>
      <div className="mainSpan">
        <div>Социальный скоринг "МЫ"</div>
      </div>
      <MainContent></MainContent>
    </div>
  );
}
