import { TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import arrow from "../icons/arrow.png";

export function EnterResult() {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [date, setDate] = useState("");

  function refresh() {
    window.location.reload();
  }

  function goResult(e) {
    e.preventDefault();
    if (code.length > 0) {
      const formdata = new FormData();
      formdata.append("userid", code);
      formdata.append("date", date);

      axios.post("https://we.rgsu.net/stat.py", formdata).then((response) => {
        try {
          const parser = new DOMParser();
          const xml = parser.parseFromString(response.data, "application/xml");
          const serializer = new XMLSerializer();

          let list = xml.querySelectorAll("block");
          let recomendationTextList = xml.querySelectorAll("text1");

          let listSU = [];

          let graphList = [];
          let graphListSU = [];
          let recomendationText = "";

          for (let i = 0; i < list.length; i++) {
            graphList.push(list[i].textContent);
          }

          localStorage.clear();

          localStorage.setItem("userID", code);

          localStorage.setItem("graphList", JSON.stringify(graphList));

          if (xml.querySelectorAll("blocksu")) {
            listSU = xml.querySelectorAll("blocksu");

            for (let i = 0; i < listSU.length; i++) {
              graphListSU.push(listSU[i].textContent);
            }

            localStorage.setItem("graphListSU", JSON.stringify(graphListSU));
          }

          localStorage.setItem(
            "socialStatus",
            xml.querySelector("full").textContent
          );

          if (xml.querySelector("fullsu")) {
            localStorage.setItem(
              "socialLevel",
              xml.querySelector("fullsu").textContent
            );
          }

          localStorage.setItem(
            "statisticsName",
            xml.querySelector("name").textContent
          );

          localStorage.setItem(
            "statisticsAge",
            xml.querySelector("age").textContent
          );

          if (recomendationTextList) {
            for (let i = 0; i < recomendationTextList.length; i++) {
              recomendationText += serializer.serializeToString(
                recomendationTextList[i]
              );
            }

            localStorage.setItem("recomendationText", recomendationText);
          }

          navigate("/statistics");
          document.body.style.overflow = "";
        } catch {
          alert("Данные введены неверно, либо результат ещё не подсчитан");
          return;
        }
      });
    } else {
      return;
    }
  }

  function changeCode(e) {
    setCode(e.target.value);
  }

  function changeDate(e) {
    setDate(e.target.value);
  }

  return (
    <div className="pop_up ">
      <div className="pop_window result">
        <div className="closeBox">
          <button onClick={refresh}>&#215;</button>
        </div>
        <form className="EnterComponents">
          <div className="enterTitle">
            Введите личный код и дату прохождения
          </div>
          <TextField
            value={code}
            onChange={changeCode}
            id="login"
            label="Код"
            variant="standard"
            className="textInputEnter"
            required
          />

          <TextField
            value={date}
            onChange={changeDate}
            label="Дата прохождения"
            type="date"
            className="textInputEnter"
            InputLabelProps={{ shrink: true, required: true }}
            required
          />

          <button className="btn" onClick={goResult}>
            <span>Продолжить</span>
            <img alt="" src={arrow} />
          </button>
        </form>
      </div>
    </div>
  );
}
