import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function Enter() {
  const navigate = useNavigate();

  function refresh() {
    window.location.reload();
  }

  return (
    <div className="pop_up ">
      <div className="pop_window enter">
        <div className="closeBox">
          <button onClick={refresh}>&#215;</button>
        </div>
        <div className="EnterComponents">
          <div className="enterTitle">Вход в личный кабинет</div>
          <TextField
            id="login"
            label="Email"
            variant="standard"
            className="textInputEnter"
          />

          <TextField
            id="password"
            label="Пароль"
            variant="standard"
            className="textInputEnter"
          />
          <button className="btn_Enter">Войти</button>
        </div>
        <div className="forgetBox">
          <button className="forgetPassword">Забыли пароль?</button>
          <button
            className="forgetRegistrate"
            onClick={() => {
              navigate("/registration");
            }}
          >
            Зарегистрироваться
          </button>
        </div>
      </div>
    </div>
  );
}
