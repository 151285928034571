import { Routes, Route } from "react-router-dom";
import { Test } from "./Pages/Test";
import { Registration } from "./Pages/Registration";
import { PersonalInfo } from "./Pages/PersonalInfo";
import { Statistics } from "./Pages/Statistics";
import { MainPage } from "./Pages/MainPage";
import { ScoringLK } from "./Pages/ScoringLK";
import { SmartCaptcha } from "@yandex/smart-captcha";
import { useEffect, useState } from "react";

function App() {
  const [token, setToken] = useState(sessionStorage.getItem("safty") || "");

  useEffect(() => {
    sessionStorage.setItem("safty", token);
    setToken(token)
  }, [token]);

  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
}, []);

  return (
    <div className="App">
      {!token ? (
        <SmartCaptcha
          sitekey="ysc1_v7Met6c6nmDv7oAy9eSdUD5SVt0K5CUKmGW4nqKZc5689993"
          onSuccess={setToken}
        />
      ) : (
        <Routes>
          {/* <Route path="/" element={<Stub />} /> */}
          <Route path="/" element={<MainPage />} />
          <Route path="/lk" element={<ScoringLK />} />
          <Route path="/test" element={<Test />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/info" element={<PersonalInfo />} />
          <Route path="/statistics" element={<Statistics />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
