
import Footer from "../Components/Footer";
import { Header } from "../Components/Header";
import { TestContent } from "../Components/TestContent";
import left from "../icons/more-group-left.png";
import right from "../icons/more-group-right.png";

export function Test() {
  

  return (
    <div className="test">
      <Header></Header>
      <div className="backGroundLeft">
        <img src={left}></img>
      </div>
      <div className="backGroundRight">
        <img src={right}></img>
      </div>
      <TestContent></TestContent>
      <Footer></Footer>
    </div>
  );
}
