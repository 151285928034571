import { useEffect, useState } from "react";
import { Enter } from "./Enter";
import { useNavigate } from "react-router-dom";

export function HeaderLK() {
  const navigate = useNavigate();
  const [pop, setPop] = useState(false);
  const [side, setSide] = useState(false);

  function goMain() {
    navigate("/");
  }

  const showSide = () => {
    window.scrollTo(0, 0);

    setSide(true);
    if (side) {
      setSide(false);
    }

    if (document.body.style.overflow !== "hidden") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  };

  const showPop = () => {
    window.scrollTo(0, 0);

    setPop(true);
    if (side) {
      setPop(false);
    }

    if (document.body.style.overflow !== "hidden") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  };

  function SideBar() {
    const navigate = useNavigate();

    function goMain() {
      navigate("/");
    }

    function goScoringLK() {
      navigate("/lk");
    }

    function goInfo() {
      navigate("/info");
    }

    function goStatistics() {
      navigate("/statistics");
    }

    return (
      <>
        {showPop ? (
          <div className="pop_up">
            <div
              className="sideBar"
              style={{ display: "flex", height: "100vh" }}
            >
              <div className="sideApp">
                <div className="sideButtonBox">
                  <div className="closeBox">
                    <button onClick={showSide}>&#215;</button>
                  </div>
                  <div className="sideButton" onClick={goScoringLK}>
                    <img
                      className="sideBarImage"
                      src={process.env.PUBLIC_URL + "/images/scoring.png"}
                      alt=""
                    />

                    <div className="sideButtonText">
                      Социальный скоринг "МЫ"
                    </div>
                  </div>
                  <div className="sideButton" onClick={goInfo}>
                    <img
                      className="sideBarImage"
                      src={process.env.PUBLIC_URL + "/images/personalData.png"}
                      alt=""
                    />

                    <div className="sideButtonText">Личный данные</div>
                  </div>
                  <div className="sideButton" onClick={goStatistics}>
                    <img
                      className="sideBarImage"
                      src={process.env.PUBLIC_URL + "/images/result.png"}
                      alt=""
                    />

                    <div className="sideButtonText">Результаты</div>
                  </div>
                </div>
                <div className="sideButton" onClick={goMain}>
                  <img
                    className="sideBarImage"
                    src={process.env.PUBLIC_URL + "/images/exit.png"}
                    alt=""
                  />

                  <div className="sideButtonText">Выход</div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }

  // SideBar.propTypes = {
  //   onCancel: PropTypes.func,
  // };
  // SideBar.defaultProps = {

  // };

  function goRegistration() {
    navigate("/registration");
    localStorage.clear();
  }

  return (
    <div className="header-color">
      <div className="header">
        <div className="logoBox">
          {/* <div className="menuIcon" onClick={showSide}></div> */}
          {/* <div className="logo" onClick={goMain}>
            МЫ
          </div> */}
        </div>

        {/* <div className="FIO">Имя Фамилия</div> */}

        {side && <SideBar />}
      </div>
    </div>
  );
}
